import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Modal, Progress, Spin } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { GoIssueClosed } from "react-icons/go";
import { MdOutlineRefresh } from "react-icons/md";
import './styles.scss';

const SettingUpModal = (props: any) => {

    return (
        <Modal
            centered
            visible={props.open}
            onCancel={() => props.close()}
            maskClosable={false}
            footer={null}
            width={500}
            closable={false}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Progress type="circle" percent={props.percent} status="normal" size={100} style={{ marginBottom: '20px' }} strokeColor={'green'} />
                <div style={{ width: '80%' }}>
                    <div className="progres-div">Registration {props.register == 2 ? <GoIssueClosed color={'green'} size={20} /> : props.register == 1 ? <MdOutlineRefresh color={'red'} size={20} /> : props.register == -1 ? <BsThreeDots color={'#A3A2A2'} size={24} /> : <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />}</div>
                    <div className="progres-div">Create Default Company {props.company == 2 ? <GoIssueClosed color={'green'} size={20} /> : props.company == 1 ? <MdOutlineRefresh onClick={() => props.defualtCompany()} color={'red'} size={20} /> : props.company == -1 ? <BsThreeDots color={'#A3A2A2'} size={24} /> : <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />}</div>
                    <div className="progres-div">Create Default Staff {props.staff == 2 ? <GoIssueClosed color={'green'} size={20} /> : props.staff == 1 ? <MdOutlineRefresh onClick={() => props.defualtStaff()} color={'red'} size={20} /> : props.staff == -1 ? <BsThreeDots color={'#A3A2A2'} size={24} /> : <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />}</div>
                    <div className="progres-div">Create Default Counter and Shift {props.Counter == 2 ? <GoIssueClosed color={'green'} size={20} /> : props.Counter == 1 ? <MdOutlineRefresh onClick={() => props.defualtCounter()} color={'red'} size={20} /> : props.Counter == -1 ? <BsThreeDots color={'#A3A2A2'} size={24} /> : <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />}</div>
                    <div className="progres-div">Create Default Product {props.product == 2 ? <GoIssueClosed color={'green'} size={20} /> : props.product == 1 ? <MdOutlineRefresh onClick={() => props.defualtProduct()} color={'red'} size={20} /> : props.product == -1 ? <BsThreeDots color={'#A3A2A2'} size={24} /> : <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />}</div>
                </div>
            </div>
        </Modal>
    );
};
export default SettingUpModal;

import React from "react";
import { Col, Row } from "react-bootstrap";
import Image from "../../../assets/images/m5-printer.jpg";

function Block5() {
  return (
    <Row className="align-items-center g-0">
      <Col xs={{ order: 2, span: 12 }} md={{ span: 5, offset: 1, order: 1 }}>
        <div className="Block2-Box2">
          <div className="Block-NewTxt">New</div>
          <div className="block2-txt1">
            Introducing Bluetooth-enabled bill <br /> printing functionality in
            our app.
          </div>
          <div className="Block1-txt2">
            With seamless connectivity, you can now effortlessly pair your
            device with compatible printers to generate and print bills on
            demand. Whether you're running a small business, managing a
            restaurant, or organizing an event, our app ensures efficient
            reliable bill printing wherever you are. Say goodbye to manual
            billing systems and hello to streamlined operations with our
            Bluetooth-enabled bill printing feature. Elevate your business
            efficiency today - download now and start printing bills
            hassle-free!
          </div>
        </div>
      </Col>
      <Col
        xs={{ order: 1, span: 12 }}
        md={{ span: 6, order: 2 }}
        className="order-1 order-md-0"
      >
        <img className="Block4-Img2" src={Image} alt="" />
      </Col>
    </Row>
  );
}

export default Block5;

import { Col, Row } from "react-bootstrap";
import Image from "../../../assets/images/block4.png";
import Invoice from "../../../assets/images/m4-invoice.jpg";
import Image1 from "../../../assets/images/triangle.png";

function Block4() {
  return (
    <Row className="align-items-center g-0">
      <Col md={6}>
        <img className="Block4-Img2 d-none d-sm-block" src={Image} alt="" />
        <img className="Block4-Img2 d-sm-none d-block" src={Invoice} alt="" />
      </Col>
      <Col md={5}>
        <Col md={{ span: 2, offset: 5 }} className="d-none d-sm-block">
          <img className="Block4-Img1" src={Image1} alt="" />
        </Col>
        <div className="Block2-Box2">
          <div className="Block-NewTxt">New</div>
          <div className="block2-txt1">
            Presenting our comprehensive invoice listing feature, designed to
            simplify your financial management tasks.
          </div>
          <div className="Block1-txt2">
            With our user-friendly interface, you can effortlessly access and
            organize all your invoices in one centralized location. From viewing
            recent transactions to tracking payment statuses, our platform
            provides you with real-time insights into your financial activities.
            Stay on top of your expenses and revenue effortlessly with our
            intuitive invoice listing tool. Experience hassle-free financial
            management - try it now!
          </div>
        </div>
      </Col>
    </Row>
    // <div className="block-Main-block5">
    //   <Row>
    //     <Col md={6}>
    //       <img className="img-3" src={Image} alt="" />
    //     </Col>
    //     <Col md={6}>
    //       <div className="Block4-Box1">
    //         <div className="block-Txt-Container">
    //           <div>New</div>
    //           <div className="block2-txt1">
    //             Presenting our comprehensive invoice <br /> listing feature,
    //             designed to simplify <br /> your financial management tasks.
    //           </div>
    //           <div className="block1-Txt2 mt-3">
    //             With our user-friendly interface, you can effortlessly access
    //             and <br />
    //             organize all your invoices in one centralized location. From{" "}
    //             <br />
    //             viewing recent transactions to tracking payment statuses, our{" "}
    //             <br />
    //             platform provides you with real-time insights into your
    //             financial <br />
    //             activities. Stay on top of your expenses and revenue
    //             effortlessly <br />
    //             with our intuitive invoice listing tool. Experience hassle-free{" "}
    //             <br />
    //             financial management - try it now!
    //           </div>{" "}
    //         </div>
    //       </div>
    //       <img src={Invoice} alt="" />
    //     </Col>
    //   </Row>
    // </div>
  );
}

export default Block4;

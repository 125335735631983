import { Col, Row } from "react-bootstrap";
import Image from "../../../assets/images/2 adding.png";
import Arrow from "../../../assets/images/2 printer-arrow.svg";
import Image1 from "../../../assets/images/m2-products.jpg";

function Block2() {
  return (
    <Row className="align-items-center g-0">
      <Col md={6} xs={12}>
        <img className="Block2-Img d-none d-sm-block" src={Image} alt="" />
        <img className="Block2-Img d-sm-none d-block" src={Image1} alt="" />
      </Col>
      <Col md={6}>
        <Col md={{ span: 2, offset: 6 }} className="d-none d-sm-block">
          <div className="Block2-Box1 mt-5">
            <img className="Block2-Img2" src={Arrow} alt="" />
          </div>
        </Col>
        <Col md={8} className="mt-5">
          <div className="Block2-Box2">
            <div className="Block-NewTxt">New</div>
            <div className="block2-txt1">
              Introducing our Retail Xpress app where selecting Product and
              adding quantities to your favorite
            </div>
            <div className="Block1-txt2">
              our innovative app designed to streamline your experience like
              never before. With our intuitive interface, you can effortlessly
              select items from extensive product catalogue and customize your
              order with just a few taps.our app simplifies the process by
              allowing you to add specific quantities of each product directly
              to your cart with ease.
            </div>
          </div>
        </Col>
      </Col>
    </Row>
  );
}

export default Block2;

import { Button, Card, Form, Input, Select, notification } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { HiOutlineCamera } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from "../../assets/images/user.png"
import OuterPageHeader from '../../component/OuterHeader/OuterPageHeader'
import ImagePicker from '../../component/imagePicker'
import LoadingBox from '../../component/loadingBox'
import PrefixSelector from '../../component/prefixSelector'
import Header from '../../component/retailHeader/header'
import Setings from '../../component/settings'
import { companyUpdate } from '../../redux/slices/userSlice'
import countries from "../../utils/CountriesWithStates.json"
import { GET, PUT } from '../../utils/apiCalls'
import './styles.scss'
import { useTranslation } from 'react-i18next'

function Company() {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const [form] = Form.useForm()
  const dispatch = useDispatch();

  const [toggle, toggleModal] = useState(false);
  const [states, setStates] = useState<any>([]);
  const [businessCategory, setBusinessCategory] = useState<any>([]);
  const [setingsModal, setSetingsModal] = useState(false);
  const [isBtLoading, setIssBtLoading] = useState(false);

  let companyInfo = user.companyInfo

  const initialValue = () => {
    form.setFieldsValue({
      code: companyInfo?.cphoneno
        ? companyInfo?.cphoneno.split(" ")[0]
        : '+91',
      phoneno: companyInfo?.cphoneno && companyInfo?.cphoneno?.split(" ")[1],
      // phoneno: companyInfo.cphoneno,
      cemail: companyInfo?.cemail,
      country: Number(companyInfo?.country),
      state: companyInfo?.state,
      bcategory: companyInfo?.bcategory,
      fulladdress: companyInfo?.fulladdress,
      defaultTerms: companyInfo?.defaultTerms,
      stripeKey: companyInfo?.stripeKey,
      cusNotes: companyInfo?.cusNotes,
      bname: companyInfo?.bname,
      from: companyInfo?.workingTimeFrom ? dayjs(companyInfo?.workingTimeFrom, 'hh:mm A') : dayjs('09:00', 'hh:mm'),
      to: companyInfo?.workingTimeTo ? dayjs(companyInfo?.workingTimeTo, 'hh:mm A') : dayjs('21:00', 'hh:mm'),
      isUniformShifts: companyInfo?.isUniformShifts,
      taxno: companyInfo?.taxno,
    })
    if (companyInfo?.country) {
      let selectedCountry: any = countries?.find(
        (country: any) => country?.id == companyInfo?.country
      );
      setStates(selectedCountry?.states);
    }
  }
  const input: any = {
    heading: 'Business Category',
    formtype: 'Business Category',
    url: 'business_category/add',
    inputnames: [
      {
        label: 'Business Category',
        name: 'btitle',
        inputtype: 'Input'
      },
    ]
  }
  const GetBusinessCategory = async () => {
    try {
      let URL = "business_category/" + user?.id;
      const { data }: any = await GET(URL, null);
      if (data) {
        setBusinessCategory(data);
      }
    } catch (error) {
      console.error(error)
    }

  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const countryId = allValues.country;
    let selectedCountry: any = countries?.find(
      (country: any) => country?.id == countryId
    );
    setStates(selectedCountry?.states);
    if (allValues.bcategory == 'category') {
      setSetingsModal(true)
    }
    if (changedValues.country) {
      form.setFieldValue('state', '')
    }
  };

  const onFinish = async (data: any) => {
    try {
      setIssBtLoading(true);
      let mobile = `${data.code} ${data.phoneno}`;
      let startTime = dayjs(data.from).format('h:mm A');
      let endTime = dayjs(data.to).format('h:mm A');
      let obj = {
        ...data,
        cphoneno: data.phoneno ? mobile : '',
        workingTimeFrom: startTime,
        workingTimeTo: endTime
      };
      let url = 'user/updateProfile/' + user?.id + '/' + user?.staff?.companyid;
      const response: any = await PUT(url, obj);
      if (response.status) {
        dispatch(companyUpdate(response.data.companyInfo));
        setIssBtLoading(false);
        notification.success({
          message: `success`,
          description: `Profile Updated Succesfully`
        });
        return response
      } else {
        setIssBtLoading(false);
        notification.error({
          message: `failed`,
          description: `profile update failed`
        });
        return response
      }
    } catch (err) {
      notification.error({
        message: `server error"`,
        description: `profile update failed try again`
      });
      setIssBtLoading(false);
      return { status: false }
    }
  };

  useEffect(() => {
    GetBusinessCategory();
    initialValue()
  }, [])
  return (
    <div className='company'>
      <div className='d-block d-lg-none'>
        <Header isNotSerach={true} />
      </div>
      <br />
      <Container>
        <OuterPageHeader title={(t as any)("company.text_1")} />
        <br />
        <div className='head'>{(t as any)("company.text_2")}</div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          onValuesChange={onValuesChange}
        >
          <Card>
            <Row>
              <Col md={4}>
                <div className="company-picture-container">
                  <div className="company-picture">
                    <img
                      src={
                        user.companyInfo?.bimage == null ||
                          user.companyInfo?.bimage == ""
                          ? Avatar
                          : user.companyInfo?.bimage
                      }
                      className="company-imgstaff"
                      onClick={() => toggleModal(true)}
                      alt=""
                    />
                    <div
                      className="company-edit"
                    >
                      <HiOutlineCamera
                        size={50}
                        onClick={() => toggleModal(true)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="company-text-box">
                    <Form.Item name="bname">
                      <Input className="company-txt1" bordered={false} />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <Card>
                  <Row>
                    <Col md="6">
                      <label className="formLabel">{(t as any)("company.placeholder")}</label>
                      <Form.Item name="phoneno" style={{ marginBottom: 10 }}
                      >
                        <Input
                          placeholder={(t as any)("company.label_1")}
                          size="large"
                          addonBefore={<PrefixSelector />}
                          onInput={(e: any) => {
                            e.currentTarget.value = e.currentTarget.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_2")}</label>
                      <Form.Item
                        name="cemail"
                        style={{ marginBottom: 10 }}
                        rules={[
                          {
                            pattern:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input placeholder={(t as any)("company.placeholder_1")} size="large" />
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_3")}</label>
                      <Form.Item
                        name="bcategory"
                        style={{ marginBottom: 10 }}
                      >
                        <Select
                          placeholder={(t as any)("company.placeholder_2")}
                          size="large"
                          showSearch={true}
                          filterOption={(input: any, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Select.Option
                            value={"category"}
                            style={{
                              color: "gray",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Add Business Category
                          </Select.Option>
                          {businessCategory?.length &&
                            businessCategory?.map((category: any) => (
                              <Select.Option
                                key={category?.id}
                                value={category?.bcategory}
                              >
                                {category?.btitle}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_4")}</label>
                      <Form.Item
                        name="fulladdress"
                        style={{ marginBottom: 10 }}
                      >
                        <Input.TextArea placeholder={(t as any)("company.placeholder_3")} size="large" />
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_5")}</label>
                      <Form.Item name="stripeKey" style={{ marginBottom: 10 }}
                      >
                        <Input placeholder={(t as any)("company.placeholder_4")} size="large" />
                      </Form.Item>
                      {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Standardize Shifts</label>
                          <Form.Item
                            name="isUniformShifts"
                            style={{ marginTop: 7 }}
                            initialValue={true}
                          >
                            <Radio.Group
                              //optionType="button"
                              buttonStyle="solid"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 5,
                              }}
                            >
                              <Button size='large'>
                                <Radio value={true}>Yes</Radio>
                              </Button>
                              <Button size='large'>
                                <Radio value={false}>No</Radio>
                              </Button>
                            </Radio.Group>
                          </Form.Item>
                        </div> */}
                    </Col>
                    <Col md="6">
                      <label className="formLabel">{companyInfo.country != 101 ? (t as any)("company.label_6.1") : (t as any)("company.label_6.2")}</label>
                      <Form.Item name="taxno" style={{ marginBottom: 10 }}
                      >
                        <Input placeholder={companyInfo.country != 101 ? (t as any)("company.label_6.1") : (t as any)("company.label_6.2")} size="large" />
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_7")}</label>
                      <Form.Item
                        name="country"
                        style={{ marginBottom: 10 }}
                      >
                        <Select
                          placeholder={(t as any)("company.placeholder_5")}
                          showSearch={true}
                          size="large"
                          filterOption={(input: any, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {countries &&
                            countries?.map((country: any) => (
                              <Select.Option key={country.id} value={country.id}>
                                {country.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_8")}</label>
                      <Form.Item
                        name="state"
                        style={{ marginBottom: 10 }}
                      >
                        <Select
                          placeholder={(t as any)("company.placeholder_6")}
                          showSearch={true}
                          size="large"
                          filterOption={(input: any, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {states &&
                            states?.map((state: any) => (
                              <Select.Option key={state.name}>
                                {state.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_9")}</label>
                      <Form.Item name="defaultTerms" style={{ marginBottom: 10 }}
                      >
                        <Input.TextArea placeholder={(t as any)("company.placeholder_7")} size="large" />
                      </Form.Item>
                      <label className="formLabel">{(t as any)("company.label_10")}</label>
                      <Form.Item name="cusNotes" style={{ marginBottom: 10 }}
                      >
                        <Input.TextArea placeholder={(t as any)("company.placeholder_8")} size="large" />
                      </Form.Item>
                      {/* <label className="formLabel">Working Time</label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Form.Item name="from" style={{ marginBottom: 10 }}
                            label='Start'
                          >
                            <TimePicker size='large' allowClear={false} format="h:mm A" />
                          </Form.Item>
                          <Form.Item name="to" style={{ marginBottom: 10 }}
                            label='End'
                          >
                            <TimePicker size='large' allowClear={false} format="h:mm A" />
                          </Form.Item>
                        </div> */}
                    </Col>
                    <Col lg={6}></Col>
                    <Col lg={6} >
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={isBtLoading}
                        block
                      >
                        {(t as any)("company.text_3")}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Form>
        <br />
        <br />
        <br />
      </Container>
      {setingsModal ? (
        <Setings
          open={setingsModal}
          onCancel={() => setSetingsModal(false)}
          formItem={input}
          refresh={() => GetBusinessCategory()}
        />
      ) : null}
      {toggle ? (
        <ImagePicker
          open={toggle}
          modalClose={() => toggleModal(false)}
          data={user}
          onFinish={(val: any) => onFinish(val)}
          image={'bimage'}
          refreshData={() => console.log('')}
        />
      ) : null}
    </div>
  )
}

export default Company
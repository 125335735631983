import { Button, Form, Input, InputNumber, Modal, notification } from 'antd'
import { POST } from '../../utils/apiCalls';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

function Setings({ open, onCancel, formItem, refresh, setFealdValue }: any) {
    const { user } = useSelector((state: any) => state.User);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (val: any) => {
        try {
            setIsLoading(true);
            let obj = {
                ...val,
                percentage: Number(val?.percentage_),
                userid: user?.id,
                adminid: user?.id,
                companyid: user?.companyInfo?.id,
                countryid: open?.countryid,
            };
            let response: any = await POST(formItem?.url, obj);
            if (response.status) {
                setIsLoading(false);
                notification.success({
                    message: "Success",
                    description: response.message,
                });
                await refresh()
                form.resetFields();
                await onCancel();
                setFealdValue(formItem.heading == 'Vat' ? response.data.percentage : response.data.id)
            } else {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: response.message,
                });
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            notification.error({
                message: "Error",
                description: 'Server Error Try again',
            });
        }
    };

    return (
        <Modal
            visible={open}
            width={500}
            closable
            footer={null}
            onCancel={() => {
                form.resetFields();
                onCancel()
            }}
        >
            <div className="Table-Txt">
                {formItem.heading}
            </div>
            <div>Fill out the form to create a {formItem.formtype}.</div>
            <hr />
            <br />
            <Form
                onFinish={onFinish}
                form={form}
            >
                <Row>
                    <Col md={12}>
                        {formItem?.inputnames?.map((item: any, index: number) => (
                            <div key={index}>
                                <label className="formLabel">{item?.label}</label>
                                <Form.Item
                                    name={item?.name}
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please Enter ${item?.label}`,
                                        },
                                    ]}
                                    initialValue={item.initialValue}
                                >
                                    {item.inputtype == 'Input' && <Input size='large' suffix={item?.suffix} />}
                                    {item.inputtype == 'InputNumber' &&
                                        // <Input
                                        //     onInput={(e: any) => {
                                        //         e.currentTarget.value = e?.currentTarget?.value?.replace(
                                        //             /[^0-9]/g,
                                        //             ""
                                        //         );
                                        //     }}
                                        //     type='number'
                                        //     size='large'
                                        //     suffix={item?.suffix}
                                        //     style={{ width: '100%' }}
                                        // />
                                        <Input
                                            onInput={(e: any) => {
                                                e.currentTarget.value = e?.currentTarget?.value?.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                            }}
                                            suffix={item?.suffix}
                                            style={{ width: '100%' }}
                                            size='large'
                                            type='number'
                                        />
                                    }
                                </Form.Item>
                            </div>
                        ))}
                    </Col>
                    <Col>
                        <Button
                            block
                            size='large'
                            onClick={() => {
                                onCancel()
                                form.resetFields();
                            }}
                        >CANCEL</Button>
                    </Col>
                    <Col>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            size='large'
                            loading={isLoading}
                        >SUBMIT</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Setings;

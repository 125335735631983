import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import UpdateEmail from "./component/emailVerify/emailUpdate";
import PaymentError from "./component/payment/components/paymentError";
import PaymentSuccess from "./component/payment/components/paymentSuccess";
import Navigation from "./navigation";
import Contact from "./screens/contact";
import ForgetScreen from "./screens/forgetPassword";
import ChangePassword from "./screens/forgetPassword/changepassword";
import HomeScreen from "./screens/homeScreen";
import LoginScreen from "./screens/loginScreen";
import SignUpScreen from "./screens/signupScreen";
import Support from "./screens/support";
import ProtectedRoute from "./utils/protectedRoute";
import './language/index'

function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#36b372",
            fontFamily: "Medium",
          },
        }}
      >
        <Routes>
          <Route index element={<HomeScreen />} />
          <Route path="/support" element={<Support />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/login"
            element={User?.auth ? <Navigate to="/auth/counter-sale" replace /> : <LoginScreen />}
          />
          <Route
            path="/signup"
            element={User?.auth ? <Navigate to="/auth/counter-sale" replace /> : <SignUpScreen />}
          />
          <Route path="/LoginHelp" element={<ForgetScreen />} />
          <Route path="/reset-password/:token" element={<ChangePassword />} />
          <Route path="/reset-password/:token" element={<ChangePassword />} />
          <Route path="/payment-failed" element={<PaymentError />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />;
          <Route
            path="/verifyemail/:verifyData"
            element={<UpdateEmail type="verify" />}
          />
          <Route
            path="/auth/*"
            element={
              <ProtectedRoute isSignedIn={User?.auth}>
                <Navigation />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ConfigProvider>
    </>
  );
}

export default App;

import { Button, Form, Input, Radio, Select, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineScan } from "react-icons/ai";
import { FaCoins } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomerForm from "../../retailExpress/customers/components/creatModal";
import QRScan from "../../retailExpress/customers/components/scaner";
import { POST } from "../../utils/apiCalls";
import LoadingBox from "../loadingBox";

function RetailExpressPaymentModal({ amount, bankList, outstanding, onFinish, onCancel }: any) {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const [form] = useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [urlLoading, setUrlLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [list, setList] = useState([]);
  const [customerForm, setCustomerForm] = useState(false);
  const [choosedBank, setChoosedBank] = useState<any>('');
  const [query, setQuery] = useState(null);
  const [qrScan, setQrScan] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState<any>(null);
  const [redeemShow, setRedeemShow] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [credit, setCredit] = useState(false)

  useEffect(() => {
    if (amount) {
      const choosedBankList = bankList.find((item: any) => item?.list?.nominalcode === "1200");
      setChoosedBank(choosedBankList);
      form.setFieldsValue({
        paymentBank: 'cash',
        amoutToPaid: Number(amount) || 0,
        payReturn: 0,
        amout: amount || 0,
        saletype: 'debit',
        outstanding: 0,
      });
    }
  }, [amount, bankList, form]);

  const paymentLink = async (scan: any) => {
    try {
      setUrlLoading(true);
      const obj = {
        amount: Number(scan) * 100,
        currency: user?.companyInfo?.countryInfo?.currencycode?.toLowerCase(),
        companyid: user?.companyInfo.id,
      };
      const data: any = await POST('payment/send-payment-link', obj);
      if (data.url) {
        setUrl(data.url);
      } else {
        setUrl('nokey');
      }
    } catch (err) {
      console.error(err);
      setUrl('nokey');
    } finally {
      setUrlLoading(false);
    }
  };

  const handleFinish = async (values: any) => {
    setIsLoading(true);
    const paymentData = {
      paymentBank: choosedBank?.list?.id,
      outStanding: values.outstanding || 0,
      amoutToPaid: Number(values.amoutToPaid) - Number(values.payReturn),
      paymentMethod: values.paymentBank,
      payReturn: values.payReturn,
      toPaidAmount: values.amoutToPaid,
      isStripe: Boolean(url),
      credit: values.saletype === "credit",
      customer: values.customer,
      redeem: redeemShow ? Number(values.redeem) : null,
    };
    const response = await onFinish(paymentData);
    if (response) {
      form.resetFields();
    }
    setIsLoading(false);
  };

  const fetchRetailCustomer = async () => {
    try {
      setCustomerLoading(true);
      const obj = {
        companyid: user?.staff?.companyid,
        query,
        page: 1,
        take: 10,
      };
      const response: any = await POST('retailCustomer/getRetailCustomer', obj);
      if (response?.status) {
        setList(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCustomerLoading(false);
    }
  };

  const scanRetailCustomer = async (scan: any) => {
    try {
      const obj = {
        companyid: user?.staff?.companyid,
        query: scan,
        page: 1,
        take: 10,
      };
      const response: any = await POST('retailCustomer/getRetailCustomer', obj);
      if (response?.data.length) {
        const customerById = response.data[0];
        form.setFieldValue('customer', customerById.id);
        await redeemCalculation(response?.data[0]);
      } else {
        form.setFieldValue('customer', null);
        notification.error({
          message: "Error",
          description: "This card is not associated with a customer.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onValuesChange = async (change: any, allValue: any) => {
    const { saletype, customer, paymentBank, redeem, amoutToPaid } = change;

    // Change debit and credit
    if (saletype) {
      const isCredit = saletype == 'credit';
      setCredit(isCredit);
      if (redeemShow) {
        await redeemCalculation(selectCustomer);
      }
    }

    // Handle customer selection
    if (customer) {
      if (customer == "custumer") {
        setCustomerForm(true);
      } else {
        const selectCustomer = list?.find((item: any) => item?.id === customer);
        if (selectCustomer) {
          await redeemCalculation(selectCustomer);
        }
      }
    }

    // paymentBank -------
    if (allValue.paymentBank && allValue.paymentBank != 'cash') {
      if (credit) {
        const redeemAmount = form.getFieldValue('amoutToPaid')
        await paymentLink(redeemAmount)
      } else {
        const redeemAmount = form.getFieldValue('redeem')
        const redeem = Number(redeemAmount) ? redeemAmount : 0
        const scanAmount = Number(amount) - Number(redeem);
        await paymentLink(scanAmount)
      }
    } else if (paymentBank && paymentBank == 'cash') {
      setUrl('')
    }

    // Redeem change
    if (redeem != undefined) {
      setRedeemAmount(redeem);
      const paidAmount = Math.max(Number(amount) - Number(redeem), 0);
      form.setFieldValue('amoutToPaid', paidAmount);
    }

    // Amount to be paid
    if (amoutToPaid !== undefined) {
      const redeemAmount = Number(allValue?.redeem) || 0;
      const paidAmount = redeemAmount + Number(amoutToPaid);
      const payReturn = paidAmount - Number(amount);

      if (credit) {
        const outstanding = payReturn <= 0;
        form.setFieldValue('payReturn', outstanding ? 0 : payReturn);
        form.setFieldValue('outstanding', outstanding ? Math.abs(payReturn) : 0);
      } else {
        form.setFieldValue('payReturn', Math.max(payReturn, 0));
      }
    }
  };

  const redeemCalculation = (val: any) => {
    const redeemLimit = Number(user?.companyInfo?.loyaltyRedeemLimit);
    const totalPoints = Number(val?.totalpoint);
    const redeemable = redeemLimit < totalPoints;

    setRedeemShow(redeemable);
    if (redeemable) {
      setSelectCustomer(val);
      const redeemAmount = Number(amount) - totalPoints;
      const isPaid = Number(amount) > totalPoints;
      setRedeemAmount(totalPoints);

      form.setFieldsValue({
        amoutToPaid: isPaid ? redeemAmount : 0,
        payReturn: 0,
        outstanding: 0,
        redeem: isPaid ? totalPoints : Number(amount),
      });
    }
  };



  useEffect(() => {
    fetchRetailCustomer();
  }, [query]);

  return (
    <div>
      <Form
        onFinish={handleFinish}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_1")}
            </div>
            <Form.Item name="saletype" noStyle>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                style={{ width: "100%" }}
              >
                <Radio.Button style={{ width: '50%', textAlign: "center" }} value="debit">DEBIT</Radio.Button>
                <Radio.Button style={{ width: '50%', textAlign: "center" }} value="credit">CREDIT</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_2")}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name="customer"
                noStyle
                rules={[{ required: credit ? true : false, message: t("sale.required") }]}
              >
                <Select
                  allowClear
                  onClear={() => {
                    setSelectCustomer(null);
                    setRedeemShow(false);
                    form.setFieldsValue({
                      amoutToPaid: amount,
                      outstanding: 0,
                      payReturn: 0,
                    });
                  }}
                  placeholder={t("sale.placeholder")}
                  showSearch
                  filterOption={false}
                  onSearch={(val: any) => setQuery(val)}
                  loading={customerLoading}
                  style={{ width: '85%' }}
                >
                  <Select.Option value="custumer"><b>{t("sale.text_27")}</b></Select.Option>
                  {list.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>{item.name || item.email || item.phone}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div
                style={{
                  width: '12%',
                  border: '1px solid #d3d3d3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  height: '32px'
                }}
                onClick={() => setQrScan(true)}
              >
                <AiOutlineScan size={30} color="#d3d3d3" />
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_3")}
            </div>
            <Form.Item
              name="paymentBank"
              rules={[{ required: true, message: t("sale.required_1") }]}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder="choose payment method"
                options={[
                  { label: t("sale.label_3.1"), value: "cash" },
                  { label: t("sale.label_3.2"), value: "cheque" },
                  { label: t("sale.label_3.3"), value: "other" },
                  { label: t("sale.label_3.4"), value: "card" },
                  { label: t("sale.label_3.5"), value: "loan" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_7")}
            </div>
            <Form.Item name="amout" noStyle>
              <Input disabled type="number" placeholder="amount" />
            </Form.Item>
          </Col>
          {redeemShow && (
            <Col sm={12} lg={6}>
              <div className="formLabel" style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                <div>Redeem</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                  <FaCoins color="#FFD700" size={24} />
                  <div style={{ marginLeft: '8px' }}>{selectCustomer?.totalpoint}</div>
                </div>
              </div>
              <Form.Item
                name="redeem"
                rules={[
                  {
                    validator: (_, value) => {
                      if (Number(selectCustomer?.totalpoint) < value) {
                        return Promise.reject(new Error(`The entered amount is much more than the customer's redeemable(${selectCustomer?.totalpoint}) points.`));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Redeem" />
              </Form.Item>
            </Col>
          )}
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_4")}
            </div>
            <Form.Item
              name="amoutToPaid"
              rules={[
                {
                  validator: (_, value) => {
                    const totalAmount = Number(value) + Number(redeemAmount);
                    if (!credit && Number(amount) > totalAmount) {
                      return Promise.reject(new Error('The paid amount is less than the total amount.'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="number" placeholder="Amount to be paid" />
            </Form.Item>
          </Col>
          <Col sm={12} lg={6}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              {t("sale.label_5")}
            </div>
            <Form.Item
              name="payReturn"
              rules={[{ required: true, message: t("sale.required_3") }]}
              noStyle
            >
              <Input disabled type="number" placeholder="Pay Return" />
            </Form.Item>
          </Col>
          {credit && (
            <Col sm={12} lg={6}>
              <div className="formLabel" style={{ marginTop: 10 }}>
                {t("sale.label_6")}
              </div>
              <Form.Item name="outstanding" noStyle>
                <Input disabled type="number" placeholder="outstanding" />
              </Form.Item>
            </Col>
          )}
          {urlLoading ? (
            <LoadingBox />
          ) : (
            url === 'nokey' ? (
              <Col sm={12}>
                <br />
                <div>{t("sale.text_12")} <b onClick={() => navigate('/auth/company')} style={{ color: '#36b372', borderBottom: '1px solid #36b372', cursor: 'pointer' }}>{t("sale.text_13")}</b></div>
              </Col>
            ) : url && (
              <Col sm={12}>
                <div style={{ height: "auto", width: "100%", paddingTop: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <div className="Table-Txt">{t("sale.text_14")}</div>
                  <QRCode size={150} value={url} />
                </div>
              </Col>
            )
          )}
        </Row>
        <br />
        <Row>
          <Col sm={6}>
            <Button
              size="large"
              block
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
              style={{ marginBottom: '6px' }}
            >
              {t("sale.text_15")}
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={url === 'nokey'}
            >
              {t("sale.text_16")}
            </Button>
          </Col>
        </Row>
      </Form>
      {customerForm && (
        <CustomerForm
          open={customerForm}
          close={() => {
            setCustomerForm(false)
            form.setFieldValue('customer', null)
          }}
          relode={async (val: any) => {
            await fetchRetailCustomer()
            form.setFieldValue('customer', val.id)
          }}
        />
      )}
      {qrScan && (
        <QRScan
          open={qrScan}
          close={() => setQrScan(false)}
          qrData={scanRetailCustomer}
        />
      )}
    </div>
  );
}

export default RetailExpressPaymentModal;

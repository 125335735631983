import { LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, Spin, Upload, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Setings from '../../../component/settings';
import API from '../../../config/api';
import { GET, POST, POST2, PUT } from '../../../utils/apiCalls';
import { clearHoldProducts, clearProduct } from "../../../redux/slices/retailExpress";
import { useTranslation } from "react-i18next";
import { RiDeleteBin4Line } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";

function ProductForm({ close, productCModal, getDatas, url, id }: any) {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const { counter } = useSelector((state: any) => state.retailExpress);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  const [locationdata, setLocationData] = useState([]);
  const [taxlist, setTaxlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [setingsModal, setSetingsModal] = useState<any>(false);
  const [input, setInput] = useState<any>([]);

  useEffect(() => {
    LoadCategory();
    LoadUnit();
    lodeLocation();
    loadTaxList();
    if (id) {
      lodeProduct()
    }
  }, []);
  const LoadCategory = async () => {
    let URL =
      API.PRODUCTCATEGORY_LIST_USER + `${user?.id}/${user?.companyInfo?.id}`;
    const data: any = await GET(URL, null);
    setCategory(data?.data);
  };

  const LoadUnit = async () => {
    let URL = API.UNIT_LIST_USER + `${user?.id}/${user?.companyInfo?.id}`;
    const data: any = await GET(URL, null);
    setUnit(data?.data);
  };

  const lodeLocation = async () => {
    try {
      let url = API.LOCATION_GET_BY_USER + user?.id + "/" + user?.companyInfo?.id;
      const data: any = await GET(url, null);
      setLocationData(data?.data);
    } catch (error) {
    }
  };

  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + `list/${user?.id}/${user?.companyInfo?.id}`;
      const data: any = await GET(URL, null);
      setTaxlist(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const lodeProduct = async () => {
    try {
      let URL = API.GET_PRODUCT_BY_ID + id;
      const data: any = await GET(URL, null);
      let product_data = data.data
      setImage(product_data.pimage)
      form.setFieldsValue({
        icode: product_data?.icode,
        idescription: product_data?.idescription,
        barcode: product_data?.barcode,
        product_category: product_data?.product_category,
        unit: product_data?.unit,
        location: product_data?.location,
        hsn_code: product_data?.hsn_code,
        sale_price: product_data.sp_price,
        cost_price: product_data.c_price,
        vat: product_data?.vat,
        includevat: product_data?.includevat == '0.00' ? false : true,
        vatamt: product_data?.vatamt,
        total_price: product_data?.includevat == '0.00' ? Number(product_data.rate) + Number(product_data.vatamt) : product_data.rate,
      })
    } catch (error) {
    }
  }

  const onFinish = async (val: any) => {
    setIsLoading(true)
    try {
      let locationArray
      let locationId
      if (val?.locationDetails?.length) {
        locationId = val?.locationDetails?.map((item: any) => item.location).join('|');
        locationArray = val?.locationDetails?.map((item: any) => {
          let location = locationdata?.find((location: any) => location?.id == item?.location)
          return {
            location: JSON.stringify(location),
            location_stock: item?.stock
          }
        })
      }

      let postObj = {
        itemtype: 'Nonstock',
        itemStock: val?.quantity_onHand,
        icode: val.icode,
        idescription: val?.idescription,
        saccount: 0,
        price: 0,
        sp_price: val?.sale_price,
        c_price: val?.cost_price,
        trade_price: 0,
        rate: val?.sale_price,
        type: 'Stock',
        notes: '',
        logintype: 'user',
        paccount: 0,
        includevat: val.includevat || false,
        userid: user?.id,
        adminid: user?.id,
        vat: val?.vat,
        vatamt: val?.vatamt,
        product_category: val?.product_category,
        usertype: 'staff',
        existingstock: false,
        date: dayjs(new Date()),
        unit: val?.unit,
        location: locationId,
        barcode: val?.barcode,
        createdBy: user?.staff?.id,
        companyid: user?.staff?.companyid,
        hsn_code: user?.companyInfo?.country == 101 ? val.hsn_code : null,
        stock: 0,
        unitDecimalValues: '',
        unitFormalName: '',
        discountamt: 0,
        discount: 0,
        costprice: val?.cost_price,
        pimage: image,
        user: user?.staff,
        counterid: Number(counter?.counter_id),
        shiftid: counter.id,
        product_loctions: locationArray
      };

      let putObj = {
        itemtype: "Stock",
        icode: val.icode,
        idescription: val?.idescription,
        saccount: 0,
        price: 0,
        sp_price: val?.sale_price,
        c_price: Number(val?.cost_price),
        trade_price: 0,
        rate: val?.sale_price,
        notes: "",
        type: "Stock",
        logintype: "user",
        paccount: 0,
        includevat: val.includevat ? 1 : 0,
        userid: user?.id,
        adminid: user?.id,
        vat: val?.vat || 0,
        vatamt: val?.vatamt,
        product_category: val?.product_category,
        existingstock: false,
        costprice: val?.cost_price,
        rlevel: null,
        stockquantity: null,
        rquantity: null,
        stock: val?.quantity_onHand,
        date: null,
        unit: val?.unit,
        location: val?.location,
        barcode: val?.barcode,
        pimage: image,
        createdBy: user?.staff?.id,
        companyid: user?.staff?.companyid,
        hsn_code: user?.companyInfo?.country == 101 ? val.hsn_code : null,
      }
      let obj = id ? putObj : postObj
      let METHERD = id ? PUT : POST
      let res: any = await METHERD(url, obj)
      if (res.status) {
        notification.success({
          message: res.message,
        });
        dispatch(clearProduct([]))
        dispatch(clearHoldProducts())
        form.resetFields()
        setImage('')
        setIsLoading(false)
        close()
        getDatas()
      } else {
        notification.error({
          message: res.message,
        });
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Server Error Try again',
      });
      setIsLoading(false)
    }
  }

  const onUpload = async (info: any) => {
    const { file } = info;
    if (file.status !== "uploading") {
      await uploadLogo(file.originFileObj);
    }
  };

  const uploadLogo = async (imagedata: any) => {
    var formdata = new FormData();
    setLoading(true);
    formdata.append("productid", "create");
    formdata.append("file", imagedata, imagedata.name);
    let graphData_url = API.PRODUCTMASTER_IMAGE_UPLOADER;
    try {
      const { data }: any = await POST2(graphData_url, formdata);

      if (data?.location) {
        setLoading(false);
        setImage(data.location);
        notification.success({
          message: " Image Uploaded",
          description: "Your image has been uploaded successfully.",
        });
      }
    } catch (error) {
      // Handle error if necessary
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onValuesChange = async (val: any, arr: any) => {
    let rate = Number(arr.sale_price);
    let vatPercent = Number(arr.vat);
    let cost_price = Number(arr.cost_price);
    let cost_price_with_vat;
    let totalPrice;
    if (val.locationDetails) {
      let quantityOnHand = arr?.locationDetails?.reduce((acc: number, item: any) => {
        const stockRate = item?.stock ? item?.stock : 0;
        return acc + stockRate;
      }, 0);
      form.setFieldValue('quantity_onHand', quantityOnHand)
    }
    if (rate && (vatPercent || Number(vatPercent) == 0)) {
      let vatAmount: any = rate * (vatPercent / 100);
      let pVatAmount: any = cost_price * (vatPercent / 100);
      let c_p_vatAmount: any = cost_price * (vatPercent / 100);
      if (val.includevat || arr.includevat) {
        vatAmount = (rate / (100 + vatPercent)) * 100;
        pVatAmount = (cost_price / (100 + vatPercent)) * 100;
        c_p_vatAmount = (cost_price / (100 + vatPercent)) * 100;

        vatAmount = (rate - vatAmount).toFixed(2);
        pVatAmount = (cost_price - pVatAmount).toFixed(2);
        c_p_vatAmount = (cost_price - vatAmount).toFixed(2);

        let multipleRate = Number(cost_price * arr.quantity_onHand)
        totalPrice = Number(multipleRate.toFixed(2));
        cost_price_with_vat = Number(cost_price.toFixed(2));

        rate = rate - vatAmount;
        cost_price = cost_price - c_p_vatAmount;
      } else {
        let onePrTotalPrice: any = Number(cost_price + pVatAmount).toFixed(2);
        totalPrice = Number(onePrTotalPrice * arr.quantity_onHand)
        cost_price_with_vat = Number(cost_price + c_p_vatAmount).toFixed(2);
      }
      form.setFieldsValue({
        vatamt: Number(vatAmount).toFixed(2),
        cgst: Number(vatAmount) / 2,
        sgst: Number(vatAmount) / 2,
        total_price: Number(totalPrice).toFixed(2),
        cost_price_with_vat: Number(cost_price_with_vat).toFixed(2),
      });
    }
    if (arr.unit == 'unit') {
      setSetingsModal(true)
      setInput({
        heading: 'Unit - Create Unit',
        formtype: 'Unit',
        url: API.UNIT_CREATE,
        inputnames: [
          {
            label: 'Formal Name',
            name: 'formalName',
            inputtype: 'Input'
          },
          {
            label: 'Symbol',
            name: 'unit',
            inputtype: 'Input'
          },
          {
            label: 'Decimal Value',
            name: 'decimalValues',
            inputtype: 'InputNumber',
            initialValue: 0
          }
        ]
      });
    }
    if (arr.product_category == 'category') {
      setSetingsModal(true)
      setInput({
        heading: 'Product Category',
        formtype: 'Product Category',
        url: API.PRODUCTCATEGORY_CREATE,
        inputnames: [
          {
            label: 'Category',
            name: 'category',
            inputtype: 'Input'
          },
        ]
      });
    }
    console.log('--------- val.locationDetails --', val.locationDetails.length)
    if (val?.locationDetails[val?.locationDetails?.length - 1]?.location == 'location') {
      setSetingsModal(true)
      setInput({
        heading: 'Location',
        formtype: 'Location',
        url: API.LOCATION_POST,
        inputnames: [
          {
            label: 'Location',
            name: 'location',
            inputtype: 'Input'
          },
          {
            label: 'Location Code',
            name: 'locationCode',
            inputtype: 'Input'
          },
        ]
      });
    }
    if (arr.vat == 'vat') {
      setSetingsModal(true)
      setInput({
        heading: 'Vat',
        formtype: 'Vat',
        url: API.CREATE_TAX_MASTER,
        countryid: user?.companyInfo?.country,
        inputnames: [
          {
            label: 'Type',
            name: 'type',
            inputtype: 'Input',
            initialValue: user?.companyInfo?.country == 101 ? "GST" : "VAT",
          },
          {
            label: 'Percentage',
            name: 'percentage_',
            inputtype: 'InputNumber',
            suffix: '%'
          },
        ]
      });
    }
  };

  const refresh = () => {
    if (input.formtype == 'Unit') {
      LoadUnit()
    } else if (input.formtype == 'Product Category') {
      LoadCategory()
    } else if (input.formtype == 'Location') {
      lodeLocation()
    } else if (input.formtype == 'Vat') {
      loadTaxList()
    }

  }
  return (
    <>
      <Modal
        open={productCModal}
        width={800}
        closable={true}
        footer={false}
        onCancel={() => {
          form.resetFields();
          close()
        }}
      >
        <div className="Table-Txt">
          {id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.2")} {(t as any)("product.text_9")}
        </div>
        <div>{id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.3")} {(t as any)("product.text_10")} {id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.2")} {(t as any)("product.text_11")}</div>
        <hr />
        <br />
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            locationDetails: [{ location: null, stock: null }]
          }}
        >
          <Row>
            <Col md={6}>
              <label className="formLabel">{(t as any)("product.label_7")}</label>
              <Form.Item
                name="icode"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_2"),
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_8")}</label>
              <Form.Item
                name="idescription"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_1"),
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_9")}</label>
              <Form.Item
                name="product_category"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_3"),
                  },
                ]}
              >
                <Select size='large' allowClear>
                  <Select.Option
                    value={'category'}
                  ><b>{(t as any)("product.text_12")}</b>
                  </Select.Option>
                  {category?.length &&
                    category.map((item: any) => (
                      <Select.Option
                        value={item.id}
                        key={item.id}
                      >
                        {item.category}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_10")}</label>
              <Form.Item
                name="unit"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_4"),
                  },
                ]}
              >
                <Select size='large' allowClear>
                  <Select.Option
                    value={'unit'}
                  ><b>{(t as any)("product.text_13")}</b>
                  </Select.Option>
                  {unit?.length &&
                    unit.map((item: any) => (
                      <Select.Option
                        value={item.id}
                        key={item.id}
                      >
                        {item.unit}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_11")}</label>
              <Form.Item
                name="barcode"
              >
                <Input size='large'
                />
              </Form.Item>
              {id ? null : (
                <>
                  <label className="formLabel">{(t as any)("product.label_12")}</label>
                  <Form.List name="locationDetails">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px'
                          }}>
                            <Form.Item
                              style={{ marginBottom: '0px', width: fields.length == 1 ? '73%' : '60%' }}
                              {...restField}
                              name={[name, 'location']}
                              rules={[{ required: true, message: "Enter a location" }]}
                            >
                              <Select
                                allowClear
                                showSearch
                                filterOption={(
                                  input: any,
                                  option: any
                                ): any => {
                                  let isInclude = false;
                                  isInclude = option.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toLowerCase());

                                  if (option.value === "location") {
                                    isInclude = true;
                                  }
                                  return isInclude;
                                }}
                                placeholder={(t as any)("product.label_12")}
                              >
                                <Select.Option
                                  value={'location'}
                                ><b>{(t as any)("product.text_14")}</b>
                                </Select.Option>
                                {locationdata?.length &&
                                  locationdata.map((item: any) => (
                                    <Select.Option
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.location}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: '0px' }}
                              {...restField}
                              name={[name, 'stock']}
                              rules={[{ required: true, message: "Enter a stock" }]}
                            >
                              <InputNumber controls={false} placeholder={'Stock'} />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <IoMdCloseCircleOutline cursor='pointer' color='red' size={24} onClick={() => remove(name)} />
                            ) : null}
                          </div>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<FiPlus size={20} />}>
                            Add Location
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}
              <label className="formLabel">{(t as any)("product.label_13")}</label>
              <div className='image-box'>
                <Upload
                  // accept="image/*"
                  style={{ color: "", padding: '0', width: '100%' }}
                  listType="picture-card"
                  showUploadList={false}
                  onChange={onUpload}
                  onPreview={onUpload}
                >
                  <br />
                  <Spin
                    spinning={loading}
                    size="large"
                    tip="Uploading..."
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "#ff9800" }}
                        spin
                      />
                    }
                  >
                    {image ? (
                      <div>
                        <img
                          src={image}
                        />
                      </div>
                    ) : (
                      <p>
                        <MdOutlinePhotoCamera
                          size="20"
                          color="rgb(160 158 158)"
                        />
                        &nbsp;
                        {(t as any)("product.text_15")}
                        <br />
                      </p>
                    )}
                  </Spin>
                </Upload>
              </div>
            </Col>
            <Col md={6}>
              <label className="formLabel">{(t as any)("product.label_14")}</label>
              <Form.Item
                name="sale_price"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_6"),
                  },
                ]}
              >
                <Input
                  onInput={(e: any) => {
                    e.currentTarget.value = e?.currentTarget?.value?.replace(
                      /[^0-9]/g,
                      ""
                    );
                  }}
                  size='large'
                  type="number"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {user?.companyInfo?.country == 101 ? (
                <>
                  <label className="formLabel">{(t as any)("product.label_15")}</label>
                  <Form.Item
                    name="hsn_code"
                    rules={[
                      {
                        required: true,
                        message: (t as any)("product.required_7"),
                      },
                    ]}
                  >
                    <InputNumber
                      size='large'
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              ) : null}
              <label className="formLabel">{user?.companyInfo?.country == 101 ? (t as any)("product.label_16") : (t as any)("product.label_17")} %</label>
              <Form.Item
                name="vat"
                rules={[
                  {
                    required: true,
                    message:
                      user?.companyInfo?.country == 101
                        ? (t as any)("product.required_8")
                        : (t as any)("product.required_9"),
                  },
                ]}
              >
                <Select size='large' allowClear>
                  <Select.Option
                    value={'vat'}
                  ><b>{(t as any)("product.text_8.2")} {user?.companyInfo?.country == 101 ? (t as any)("product.label_16") : (t as any)("product.label_17")}</b>
                  </Select.Option>
                  {taxlist?.length &&
                    taxlist?.map((item: any) => (
                      <Select.Option key={item?.percentage}>
                        {`${item?.percentage} %`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_18")}</label>
              <Form.Item
                name="includevat"
                style={{ border: 0, margin: 0 }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <div style={{ marginBottom: '32px' }}></div>
              <label className="formLabel">{(t as any)("product.label_19")}</label>
              <Form.Item
                name="vatamt"
              >
                <InputNumber
                  disabled
                  type="number"
                  size='large'
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <label className="formLabel">{(t as any)("product.label_20")}</label>
              <Form.Item
                name="cost_price"
                rules={[
                  {
                    required: true,
                    message: (t as any)("product.required_10"),
                  },
                ]}
              >
                <Input
                  onInput={(e: any) => {
                    e.currentTarget.value = e?.currentTarget?.value?.replace(
                      /[^0-9]/g,
                      ""
                    );
                  }}
                  type="number"
                  size='large'
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {id ? null : (
                <>
                  <label className="formLabel">{(t as any)("product.label_21")}</label>
                  <Form.Item
                    name="quantity_onHand"
                    rules={[
                      {
                        required: true,
                        message: (t as any)("product.required_11"),
                      },
                    ]}
                  >
                    <Input
                      readOnly
                      onInput={(e: any) => {
                        e.currentTarget.value = e?.currentTarget?.value?.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      type="number"
                      size='large'
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <label className="formLabel">{(t as any)("product.label_6")}</label>
                  <Form.Item
                    name="total_price"
                  >
                    <InputNumber
                      disabled
                      type="number"
                      size='large'
                      controls={false}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              )}
              <Button
                loading={isloading}
                type="primary"
                htmlType="submit"
                block
                size="large"
              >
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal >
      <Setings
        open={setingsModal}
        onCancel={() => {
          let fealdName = input.formtype == 'Vat' ? 'vat' : input.formtype == 'Location' ? 'location' : input.formtype == 'Unit' ? 'unit' : 'product_category'
          form.setFieldValue(fealdName, null)
          setSetingsModal(false)
        }
        }
        formItem={input}
        refresh={() => refresh()}
        setFealdValue={(val: any) => {
          let fealdName = input.formtype == 'Vat' ? 'vat' : input.formtype == 'Location' ? 'location' : input.formtype == 'Unit' ? 'unit' : 'product_category'
          form.setFieldValue(fealdName, val)
        }}
      />
    </>
  )
}

export default ProductForm
import { Button, ConfigProvider, Form, Input, notification } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/cash_desk.png";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from "../../component/header";
import SettingUpModal from "../../component/lodingProgrus";
import API from "../../config/api";
import { login, setBaseUrl, setMailWerify, setToken, update } from "../../redux/slices/userSlice";
import { Store } from "../../redux/store";
import { CREATEBASEURL, GETBASEURL, POST, REGISTERPOST } from "../../utils/apiCalls";
import "./styles.scss";

function SignupScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loding, setLoding] = useState(false)
  const [progrusModal, setProgrusModal] = useState(false)
  const [company, setCompany] = useState(0)
  const [staff, setStaff] = useState(-1)
  const [Counter, setCounter] = useState(-1)
  const [product, setProduct] = useState(-1)
  const [percent, setPercent] = useState(0)
  const [register, setRegister] = useState(-1)
  const [passwordValidation, setPasswordValidation] = useState<any>({
    status: "",
    help: "",
  });

  const onFinish = async (formData: any) => {
    try {
      setRegister(0)
      setLoding(true)
      const url = API.BASE_URL + API.LOGIN_REGISTRATION;
      const requestBody = {
        firstname: formData.username,
        email: formData.email,
        password: formData.password,
        isRetail: true
      };
      const response: any = await REGISTERPOST(url, requestBody);
      if (response.status == true) {
        dispatch(setToken(response?.data?.token));
        response.data['password'] = formData.password;
        dispatch(login({
          data: response?.data,
          auth: false
        }));
        createBaseUrl(response?.data);
      } else {
        notification.error({
          message: "Fail!",
          description: response.message,
        });
        setRegister(1)
        setLoding(false)
      }
    } catch (error) {
      console.error("Signup failed:", error);
      setRegister(1)
    }
  };

  const createBaseUrl = async (userData: any) => {
    try {
      const url = API.BASE_URL;
      const date = new Date();
      const year = date.getFullYear();
      const lastParams = `${year}-${year + 1}`;
      const body = {
        adminid: userData.id,
        email: userData.email,
        isActive: true,
        urlName: lastParams,
        baseUrl: url,
      };
      const endpoint = 'base';
      const response: any = await CREATEBASEURL(endpoint, body);
      if (response.status == true) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        setRegister(2)
        setProgrusModal(true)
        setLoding(false)
        await defualtCompany()
      } else {
        setRegister(1)
        setLoding(false)
      }
    } catch (error) {
      console.error("Base URL creation failed:", error);
      setRegister(1)
      setLoding(false)
    }
  };

  const defualtCompany = async () => {
    try {
      setCompany(0)
      const userData: any = Store.getState().User.user;
      let obj = {
        bname: "Default Company",
        bcategory: "Default Category",
        financial_year_start: new Date(),
        adminid: userData.id,
        userid: userData.id,
        country: 105,
        workingTimeFrom: '09:00 AM',
        workingTimeTo: '09:00 PM',
      };
      let url = API.CREATE_COMPANY;
      const response: any = await POST(url, obj);
      if (response.data) {
        let obj = {
          ...userData,
          companyInfo: response.data,
        }
        dispatch(update(obj));
        setPercent(25)
        setCompany(2)
        await defualtStaff()
      } else {
        setCompany(1)
      }
    } catch (error) {
      console.error(error);
      setCompany(1)
    }
  };

  const defualtStaff = async () => {
    try {
      setStaff(0)
      const userData: any = Store.getState().User.user;
      let url = API.CREATE_DEFAULT_STAFF;
      let staffObj = {
        name: userData?.firstname,
        password: userData?.password,
        email: userData?.email,
        adminid: userData?.id,
        createdBy: userData?.id,
        companyid: userData.companyInfo.id,
        userdate: new Date(),
        is_deleted: false,
      };
      const response: any = await POST(url, staffObj);
      if (response.status) {
        setPercent(50)
        setStaff(2)
        await defualtCounter()
      } else {
        setStaff(1)
      }
    } catch (error) {
      console.error(error);
      setStaff(1)
    }
  };

  const defualtCounter = async () => {
    try {
      setCounter(0)
      const userData: any = Store.getState().User.user;

      let url = `billing_counter/addCounter`
      const obj = {
        adminid: userData.id,
        companyid: userData?.companyInfo?.id,
        balance: 0,
        name: "counter 1",
        sdate: new Date(),
        shiftlist: [
          {
            fromtime: "9:00 AM",
            totime: "9:00 PM",
            name: "Day shift"
          }
        ]
      }
      const response: any = await POST(url, obj)
      if (response.status) {
        setCounter(2)
        setPercent(75)
        await defualtProduct()
      } else {
        setCounter(1)
      }
    } catch (error) {
      console.error(error)
      setCounter(1)
    }
  }

  const defualtProduct = async () => {
    try {
      setProduct(0)
      const userData: any = Store.getState().User.user;

      let reqObj = {
        itemtype: "Nonstock",
        icode: "defualt-product",
        idescription: "defualt-product",
        saccount: 4000,
        sp_price: 100,
        rate: 100,
        type: "Nonstock",
        logintype: "user",
        userid: userData.id,
        adminid: userData.id,
        vat: 10,
        vatamt: 10,
        // product_category: "defualt-category",
        date: "2024-04-21T18:30:00.000Z",
        // unit: "NOS",
        // location: "defualt-location",
        createdBy: userData.id,
        companyid: userData?.companyInfo?.id,
        paccount: 0,
        includevat: 0,
        c_price: 0,
        existingstock: false,
        product_loctions: []
      }

      let URL = API.ADD_PRODUCT;
      const response: any = await POST(URL, reqObj);
      if (response.status) {
        setPercent(100)
        setProduct(2)
        await baseUrl()
      } else {
        setProduct(1)
      }
    } catch (error: any) {
      console.error(error);
      setProduct(1)
    }
  };

  const baseUrl = async () => {
    const userData: any = Store.getState().User.user;
    try {
      let endpoint = "base/active/email/" + userData.email;
      const response: any = await GETBASEURL(endpoint, {});
      if (response.status) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        submit({
          email: userData?.email,
          password: userData?.password
        }, response?.data?.baseUrl);
        setProgrusModal(false)
      } else {
      }
    } catch (error) {
      console.error(error)
    }
  };

  const submit = async (values: any, BASEURL: any) => {
    try {
      let url = 'auth/staff/email-login';
      var loginRes: any = await REGISTERPOST(BASEURL + url, {
        ...values,
        type: "staff"
      });
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(login({
          data: loginRes?.data,
          auth: true
        }));
        if (loginRes?.data.emailverified) {
          dispatch(setMailWerify());
        }
        navigate("/auth/counter-sale")
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const atLeastOneRequired = ({ getFieldValue }: any) => ({
    validator(_: any, value: any) {
        const minLength = 8;
        const specialCharacterRegex = /[.*@!#%&()^~]/;
        const digitRegex = /\d/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const repetitiveRegex = /(.)\1{2,}/;

        if (!value || value.length === 0) {
            return Promise.reject(new Error("Password is required"));
        } else if (value.length < minLength) {
            return Promise.reject(new Error(`Password must be at least ${minLength} characters long`));
        } else if (!uppercaseRegex.test(value)) {
            return Promise.reject(new Error("Password must contain at least one uppercase letter"));
        } else if (!lowercaseRegex.test(value)) {
            return Promise.reject(new Error("Password must contain at least one lowercase letter"));
        } else if (!digitRegex.test(value)) {
            return Promise.reject(new Error("Password must contain at least one digit"));
        } else if (!specialCharacterRegex.test(value)) {
            return Promise.reject(new Error("Password must contain at least one special character"));
        } else if (repetitiveRegex.test(value)) {
            return Promise.reject(new Error("Password must not contain three or more repetitive characters"));
        }
        
        return Promise.resolve();
    },
});

  const validatePassword = (value: any) => {
    // Your password validation logic
    const minLength = 8;
    const specialCharacterRegex = /[.*@!#%&()^~]/;
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/; // Regex for uppercase letters
    const lowercaseRegex = /[a-z]/; // Regex for lowercase letters
    const repetitiveRegex = /(.)\1{2,}/; // Regex to check for 3 or more repetitive characters

    if (value.length === 0) {
      setPasswordValidation({
        status: "error",
        help: "Password is required",
      });
    } else if (!uppercaseRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one uppercase letter.",
      });
    } else if (!lowercaseRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one lowercase letter.",
      });
    } else if (!specialCharacterRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one special character.",
      });
    } else if (!digitRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one digit.",
      });
    } else if (value.length < minLength) {
      setPasswordValidation({
        status: "error",
        help: "Password must be at least 8 characters long.",
      });
    } else if (repetitiveRegex.test(value)) { // Check for repetitive characters
      setPasswordValidation({
        status: "error",
        help: "Please enter a strong password.",
      });
    } else {
      setPasswordValidation({
        status: "success",
        help: "",
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#18a762",
          fontFamily: "Regular",
          borderRadius: 4,
          colorTextPlaceholder: "#888c99",
        },
      }}
    >
      <div>
        <Header />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="d-none  d-lg-flex justify-content-center align-items-center">
              <div className="signup_box" >
                <p>Simplify management with our Retail Xpress.</p>
                {/* <div className="bordered-div"></div> */}
                <div className="signup_text">Simplyfy your billing management whith our user-friendly Retail Xpress</div>
                <img src={image} alt="" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                <div className="signupScreen-Box2">
                  <img src={Logo} alt="" />
                </div>
                <div className="signup_head">Welcome Back</div>
                <div className="signup_subhead">Please signup to your account</div>
                <Form
                  style={{ width: '100%' }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <label className="formLabel">Username</label>
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input className="signup_input" size="large" />
                  </Form.Item>
                  <label className="formLabel">Email</label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Enter a valid email",
                      },
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input className="signup_input" size="large" type="email" />
                  </Form.Item>
                  <label className="formLabel">Password</label>
                  <Form.Item
                    name="password"
                    rules={[
                      atLeastOneRequired
                    ]}
                  >
                    <Input.Password
                      className="signup_input"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item >
                    <Button
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      className="signup_btn"
                      loading={loding}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div className="footer_text">You have an account? <div style={{ color: '#ffbc49' }} onClick={() => navigate("/login")}> &nbsp; Signin</div></div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
      {progrusModal &&
        <SettingUpModal
          open={progrusModal}
          company={company}
          staff={staff}
          Counter={Counter}
          product={product}
          percent={percent}
          register={register}
          defualtCompany={() => defualtCompany()}
          defualtStaff={() => defualtStaff()}
          defualtCounter={() => defualtCounter()}
          defualtProduct={() => defualtProduct()}
        />
      }
    </ConfigProvider>
  )
}

export default SignupScreen
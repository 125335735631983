import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import "./styles.scss";
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { Drawer } from "antd";

function Header() {
  const { user } = useSelector((state: any) => state.User);
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className={"header-Box1"}>
        <Row className="align-items-center g-0">
          <Col md={8} sm={10} xs="10">
            <img
              className="header-Logo"
              src={Logo}
              alt=""
              onClick={() => navigate("/")}
            />
          </Col>
          <Col md={4} sm={2} xs="2">
            <div className="header-Box-mobile2">
              <IoMenu onClick={() => setDrawer(true)} size={28} />
            </div>
            <div className="header-Box2">
              <div className="header-Txt2" onClick={() => navigate("/")}>
                Home
              </div>
              <div className="header-Txt2" onClick={() => navigate("/support")}>
                Support
              </div>
              <div className="header-Txt2" onClick={() => navigate("/contact")}>
                Contact Us
              </div>
              <div
                className="header-Txt2"
                onClick={() => navigate("/login")}>
                Login
              </div>
              <div className="header-Txt2" onClick={() => navigate("/signup")}>
                Sign up
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Drawer
        onClose={() => setDrawer(false)}
        open={drawer}
        children={
          <div className="header-Box4">
            <>
              {" "}
              <img
                className="header-Logo"
                src={Logo}
                alt=""
                onClick={() => {
                  navigate("/");
                  setDrawer(false);
                }}
              />
            </>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/");
                setDrawer(false);
              }}
            >
              Home
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/support");
                setDrawer(false);
              }}
            >
              Support
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/contact");
                setDrawer(false);
              }}
            >
              Contact Us
            </div>
            <div
              className="header-Txt2"
              onClick={() =>
                !user.staff
                  ? (navigate("/login"), setDrawer(false))
                  : (navigate("/auth/counter-sale"), setDrawer(false))
              }
            >
              Login
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/signup");
                setDrawer(false);
              }}
            >
              Sign up
            </div>
          </div>
        }
      ></Drawer>
    </>
  );
}

export default Header;

import { Button, Form, Input, notification, Checkbox, ConfigProvider } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from "../../component/header";
import { login, setBaseUrl, setMailWerify, setToken } from "../../redux/slices/userSlice";
import { CREATEBASEURL, GETBASEURL, REGISTERPOST } from "../../utils/apiCalls";
import "./styles.scss";
import image from "../../assets/images/cash_desk.png";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values: any, BASEURL: any) => {
    try {
      let url = 'auth/staff/email-login';
      var loginRes: any = await REGISTERPOST(BASEURL + url, {
        ...values,
        type: "staff"
      });
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(login({
          data: loginRes?.data,
          auth: true
        }));
        if (loginRes?.data.emailverified) {
          dispatch(setMailWerify());
        }
        notification.success({
          message: "Success",
          description: "Logged in successfully",
        });
        navigate("/auth/counter-sale")
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed",
          description: loginRes?.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Server Error",
        description: "Failed to login,Please try again later",
      });
      setIsLoading(false);
    }
  };

  const onFinish = async (data: any) => {
    try {
      setIsLoading(true);
      let endpoint = "base/staff/activeUrl";
      const response: any = await CREATEBASEURL(endpoint, { email: data.email });
      if (response.status) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        submit(data, response?.data?.baseUrl);
      } else {
        notification.error({
          message:
            "Oops! Something went wrong with your sign-up. Please try again later or contact support for help.",
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#18a762",
          fontFamily: "Regular",
          borderRadius: 4,
          colorTextPlaceholder: "#888c99",
        },
      }}
    >
      <div>
        <Header />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="d-none  d-lg-flex justify-content-center align-items-center">
              <div className="login_box" >
                <p>Simplify management with our Retail Xpress.</p>
                <div className="login_text">Simplyfy your billing management with our user-friendly Retail Xpress</div>
                <img src={image} alt="" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                <div className="loginScreen-Box2">
                  <img src={Logo} alt="" />
                </div>
                <div className="login_head">Welcome Back</div>
                <div className="login_subhead">Please login to your account</div>
                <Form
                  style={{ width: '100%' }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <label className="formLabel">Email or Staff ID</label>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email or username!' }]}
                  >
                    <Input className="login_input" size="large" />
                  </Form.Item>
                  <label className="formLabel">Password</label>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                  >
                    <Input.Password className="login_input" size="large" />
                  </Form.Item>
                  <div className="login_checkbox">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                    >
                      <Checkbox>Remember me</Checkbox>

                    </Form.Item>
                    <div>
                      <div onClick={() => navigate('/LoginHelp#')} style={{ color: '#ffbc49', cursor: 'pointer' }}>Forgot password</div>
                    </div>
                  </div>
                  <Form.Item >
                    <Button
                      loading={isLoading}
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      className="login_btn"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div className="footer_text">Don't have an account? <div style={{ color: '#ffbc49' }} onClick={() => navigate("/signup")}> &nbsp; Signup</div></div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </ConfigProvider>
  )
}

export default LoginScreen
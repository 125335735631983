import { Button, Modal, Pagination, Radio, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import LoadingBox from "../../component/loadingBox";
import NoData from "../../component/nodata";
import Header from "../../component/retailHeader/header";
import API from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import ProductForm from "./components/form-product";
import ServiceForm from "./components/form-service";
import ProductItemList from "./components/productItem";
import "./styles.scss";
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader";
import { useTranslation } from "react-i18next";
import ClockInModel from "../../component/clockInModel";

function RetailProduct() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);

    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState<any>({});

    const [categories, setCategories] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [product, setProduct] = useState();
    const [category, setCategory] = useState(0);
    const [loding, setLoading] = useState<any>(true);
    const [productCModal, setProductCModal] = useState(false);
    const [type, setType] = useState('both')
    const [selectBank, setSlectedBank] = useState<any>([]);
    const [counterModal, setCounterModal] = useState(false);

    useEffect(() => {
        getBankList();
    }, []);

    useEffect(() => {
        getCategories();
        getDatas();
    }, [category, product, page, type]);

    const getCategories = async () => {
        try {
            const url = `ProductCategory/user/` + user?.id + '/' + user?.staff?.companyid;
            const response: any = await GET(url, {});
            var data: any = [{ key: 0, label: "ALL PRODUCTS" }];
            if (response?.data?.length) {
                response?.data?.map((item: any) => {
                    let obj = { key: item?.id, label: item?.category.toUpperCase() };
                    data.push(obj);
                });
            }
            setCategories(data);
        } catch (error) { }
    };

    const getDatas = async (val?: any) => {
        try {
            if (!val) {
                setLoading(true)
            }
            let body =
            {
                id: user?.id,
                companyid: user?.staff?.companyid,
                type: type,
                category: category,
                name: val ? 0 : product,
                barcode: val,
            }
            const url = `retail/productlist?order=DESC&page=${page}&take=8`;
            const responce: any = await POST(url, body);
            responce.data.forEach((item: any) => {
                item["selection"] = false;
            });
            if (responce.data.length) {
                if (val) {
                    return responce.data
                } else {
                    setData(responce.data);
                    setMeta(responce?.meta);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setData([])
            }
        } catch (error) {
            console.error("Error: ", error);
            setData([])
        }
    };

    const getBankList = async () => {
        try {
            let url = "account_master/getBankList/" + user?.id + '/' + user?.staff?.companyid;
            const { data }: any = await GET(url, null);
            setSlectedBank(data.bankList);
        } catch (error) { }
    };

    let radiButtunType = [
        { name: 'both', label: (t as any)("header.text") },
        { name: 'Service', label: (t as any)("header.text_1") }
    ]
    return (
        <>
            <Header
                product={(val: any) => setProduct(val)}
                type={type}
                typeList={radiButtunType}
                setType={(val: any) => {
                    setPage(1)
                    setType(val)
                }}
            />
            {/* <Tabs
                size="small"
                defaultActiveKey={category.toString()}
                items={categories}
                onChange={(val: any) => {
                    setCategory(val)
                    setPage(1)
                }}
                tabBarStyle={{ backgroundColor: "white", margin: 0, padding: '0px 10px' }}
            /> */}
            <div className="product">
                <br />
                <Container>
                    <OuterPageHeader title={(t as any)("product.text")} children={
                        <Button
                            type="primary"
                            onClick={() => {
                                if (counter.id) {
                                    setProductCModal(true)
                                } else {
                                    setCounterModal(true)
                                }
                            }}
                        >
                            {(t as any)("product.text_1")} {type == 'both' ? (t as any)("product.text_1.1") : (t as any)("product.text_1.2")}
                        </Button>
                    } />
                    <br />

                    <Row style={{ marginRight: 0, marginLeft: 0 }}>
                        <Col sm={12} xs={12} style={{ margin: 0, padding: 0 }}>
                            <br />

                            <div className="product-box1">
                                <Row className="mt-3">
                                    {loding ? (
                                        <LoadingBox />
                                    ) : (
                                        data && data.length ? (
                                            data.map((item: any, index: number) => (
                                                <Col
                                                    sm={6}
                                                    xs={12}
                                                    lg={3}
                                                    key={index}
                                                    style={{ marginBottom: 20 }}
                                                >
                                                    <ProductItemList
                                                        bankList={selectBank}
                                                        item={item}
                                                        currencycode={user?.companyInfo?.countryInfo?.symbol}
                                                        getDatas={() => getDatas()}
                                                        setCounterModal={() => setCounterModal(true)}
                                                        counter={counter?.id ? true : false}
                                                        type={type}
                                                    />
                                                </Col>
                                            ))
                                        ) : (
                                            <NoData text={(t as any)("product.text_4")} />
                                        )
                                    )}
                                </Row>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '10px' }}>
                                <Pagination
                                    total={meta?.itemCount}
                                    showSizeChanger={false}
                                    showTotal={(total) => `${(t as any)("product.text_2")} ${meta?.itemCount} ${(t as any)("product.text_3")}`}
                                    onChange={(page) => setPage(page)}
                                />
                            </div>
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row >
                </Container >
                {productCModal && type == 'Service' ? (
                    <ServiceForm
                        productCModal={productCModal}
                        close={() => setProductCModal(false)}
                        getDatas={getDatas}
                        url={'ProductMaster/add'}
                    />
                ) : (
                    <ProductForm
                        productCModal={productCModal}
                        close={() => setProductCModal(false)}
                        getDatas={getDatas}
                        url={API.PURCHASE_PRODUCT}
                    />
                )}
                {counterModal ? (
                    <ClockInModel
                        open={counterModal}
                        onCancel={() => setCounterModal(false)}
                        text={(t as any)("product.text_18")}
                    />
                ) : null}
            </div>
        </>
    );
}

export default RetailProduct;
import { Button, Modal, Pagination, Tabs, notification } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import RetailExpressPaymentmodal from "../../component/RetailExpressPaymentModal/pymentModal";
import LoadingBox from "../../component/loadingBox";
import NoData from "../../component/nodata";
import PrintModal from "../../component/printModal/printModal";
import Header from "../../component/retailHeader/header";
import { addCartProducts, addHoldProduct, addProductQuantity, addProducts, clearHoldProducts, clearProduct, enterProductQuantity, minusProductQuantity, removeHoldProduct, removeProducts } from "../../redux/slices/retailExpress";
import { Store } from "../../redux/store";
import { GET, POST } from "../../utils/apiCalls";
import HoldInvoice from "./components/hold";
import ListItem from "./components/listItem";
import ProductItem from "./components/productItem";
import { printTemplate } from "./components/templates";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import ClockInModel from "../../component/clockInModel";


function RetailExpress() {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const { counter } = useSelector((state: any) => state.retailExpress);
  const dispatch = useDispatch();

  let products: any = Store.getState().retailExpress.products;
  let productsStock: any = Store.getState().retailExpress.holdProducts;

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});

  const [categories, setCategories] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [allProduct, setAllProduct] = useState<any>([]);
  const [allService, setAllService] = useState<any>([]);
  // const [isChange, setIsChange] = useState<any>(false);
  const [product, setProduct] = useState();
  const [category, setCategory] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [selectBank, setSlectedBank] = useState<any>([]);
  const [loding, setLoading] = useState<any>(true);
  const [btLoding, setBtLoading] = useState<any>(false);
  const [totalQuatity, setTotalQuatity] = useState<any>(false);
  const [ledgers, setLedgers] = useState([]);
  const [template, setTemplate] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [counterModal, setCounterModal] = useState(false);
  const [type, setType] = useState('Stock')
  const [isFixed, setIsFixed] = useState(true);

  // const fetchUser = async () => {
  //   try {
  //     let url = API.GET_USER_DETAILS;
  //     const response: any = await GET(url, null);
  //     if (!response?.data?.emailverified) {
  //       setOpenModal(true);
  //     } else {
  //       dispatch(setMailWerify());
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    allProductData()
    allServiceData()
  }, []);

  useEffect(() => {
    getCategories();
    getBankList()
    getDatas();
    getInvoiceNo()
    calculation()
    getLedgers()
  }, [category, product, page, type]);

  const calculation = () => {
    try {
      let taxAmount_: any = 0;
      let totalQuatity: any = 0;
      // setIsChange(true);
      const sumOfProducts = products.reduce((acc: any, item: any) => {
        totalQuatity += item.quantity_no
        const total = Number(item.rate) * Number(item.quantity_no);
        const taxAmount = Number(item?.vatamt) * Number(item.quantity_no);
        let newTaxAmount: any = taxAmount
        taxAmount_ += newTaxAmount;
        const finalPrice =
          item.includevat == "1.00"
            ? Number(total) - Number(newTaxAmount)
            : Number(total);
        return acc + finalPrice;
      }, 0);
      setTotalQuatity(totalQuatity)
      setTotal(sumOfProducts);
      setTax(taxAmount_);
      setTimeout(() => {
        // setIsChange(false);
      }, 1);
    } catch (error) {
      console.error(error)
    }
  };

  const getCategories = async () => {
    try {
      const url = `ProductCategory/user/` + user?.id + '/' + user?.staff?.companyid;
      const response: any = await GET(url, {});
      var data: any = [{ key: 0, label: "ALL PRODUCTS" }];
      if (response?.data?.length) {
        response?.data?.map((item: any) => {
          let obj = { key: item?.id, label: item?.category.toUpperCase() };
          data.push(obj);
        });
      }
      setCategories(data);
    } catch (error) { }
  };

  const allProductData = async () => {
    try {
      let productuul = `ProductMaster/user/Stock/${user?.id}/${user?.staff?.companyid}`;
      const { data: products }: any = await GET(productuul, null);
      setAllProduct(products);
    } catch (error) { }
  };

  const allServiceData = async () => {
    try {
      let productuul = `ProductMaster/user/Service/${user?.id}/${user?.staff?.companyid}`;
      const { data: products }: any = await GET(productuul, null);
      setAllService(products);
    } catch (error) { }
  };

  const getDatas = async (val?: any) => {
    try {
      if (!val) {
        setLoading(true)
      }
      let body =
      {
        id: user?.id,
        companyid: user?.staff?.companyid,
        type: type,
        category: category,
        name: val ? 0 : product,
        barcode: val,
      }
      const url = `retail/productlist?order=DESC&page=${page}&take=8`;
      const responce: any = await POST(url, body);
      responce.data.forEach((item: any) => {
        item["selection"] = false;
      });
      if (responce.data.length) {
        if (val) {
          return responce.data
        } else {
          setData(responce.data);
          setMeta(responce?.meta);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setData([])
        setMeta({})
      }
    } catch (error) {
      console.error("Error: ", error);
      setData([])
    }
  };
  const handleAddToCart = (item: any) => {
    const findInd = products.findIndex((val: any) => val.id === item.id);
    if (findInd >= 0) {
      dispatch(addProductQuantity(products[findInd].id))
      products = Store.getState().retailExpress.products;
      calculation();
    } else {
      if (!item.quantity_no) {
        item["quantity_no"] = 1;
      }
      dispatch(addProducts(item));
      products = Store.getState().retailExpress.products;
      calculation();
    }
  };

  const handleClearCart = () => {
    if (products.length) {
      dispatch(clearProduct([]));
      products = Store.getState().retailExpress.products;
      notification.success({ message: "Cart cleared successfully" });
      calculation();
    } else {
      notification.error({ message: "No items in the cart." });
    }
  };

  const clearAtIndex = (id: any) => {
    dispatch(removeProducts(id));
    products = Store.getState().retailExpress.products;
    calculation();
  };

  const add = (val: any) => {
    // setIsChange(true)
    dispatch(addProductQuantity(val));
    products = Store.getState().retailExpress.products;
    setTimeout(() => {
      // setIsChange(false);
      calculation();
    }, 1);
  }

  const mainus = (val: any) => {
    // setIsChange(true)
    dispatch(minusProductQuantity(val));
    products = Store.getState().retailExpress.products;
    setTimeout(() => {
      // setIsChange(false);
      calculation();
    }, 1);
  }

  const enter = (val: any) => {
    dispatch(enterProductQuantity(val));
    products = Store.getState().retailExpress.products;
    setTimeout(() => {
      calculation();
    }, 1);
  }

  const getInvoiceNo = async () => {
    try {
      let invoiceurl = "user_settings/getInvoiceNo/" + user?.id + '/' + user?.staff?.companyid + "/sales";
      const { data: invnumber }: any = await GET(invoiceurl, null);
      setInvoiceNumber(invnumber);
    } catch (error) { }
  };

  const getLedgers = async () => {
    try {
      let url = "account_master/defualt-ledger/sales/" + user?.id;
      const { data: bank }: any = await GET(url, null);
      const filterData = bank.find((item: any) => {
        if (type == 'Stock') {
          return item.id == 1;
        } else {
          return item.id == 2;
        }
      }); setLedgers(filterData);
    } catch (error) { }
  };
  const handleSubmit = async (val?: any) => {
    try {
      // setBtLoading(true)
      let paymentInfo = {
        id: val.paymentBank,
        bankid: val.paymentBank,
        outstanding: val.outStanding,
        amount: val.amoutToPaid.toString(),
        date: dayjs(new Date(), "YYYY-MM-DD"),
        type: val.paymentMethod,
        paidmethod: val.paymentMethod,
      };
      let column = products.map((item: any, index: number) => {
        let foundedProduct: any
        if (item?.itemtype == 'Stock') {
          foundedProduct = allProduct.find(
            (product: any) => {
              return product.id == item.id
            }
          );
        } else {
          foundedProduct = allService.find(
            (product: any) => {
              return product.id == item.id
            }
          );
        }

        let productTotal = 0
        let productVat = 0
        if (item.includevat == '1.00') {
          const total = Number(item.rate) * Number(item.quantity_no);
          const taxAmount = (Number(item.vatamt) * (item.quantity_no));
          productVat = Number(taxAmount);
          productTotal = total
        } else {
          const total = Number(item.rate) * Number(item.quantity_no);
          const taxAmount = (Number(item.vatamt) * (item.quantity_no));
          productVat = Number(taxAmount);
          productTotal = total + productVat
        }

        let productLedger = {
          category: "13",
          id: 1,
          laccount: "Sales-Products",
          nominalcode: "4000",
        };
        let serviceLedger = {
          category: "24",
          id: 2,
          laccount: "Sales-Services",
          nominalcode: "4001",
        };
        return {
          id: item.id,
          discount: '0.00',
          discountamt: '0.00',
          productId: item.id,
          product: foundedProduct,
          idescription: foundedProduct?.idescription,
          description: foundedProduct?.idescription,
          vat: item.vat,
          includevat: item.includevat == '1.00' ? true : false,
          incomeTax: item.vat,
          percentage: item.vat,
          costprice: item.rate,
          ledgerDetails: type == 'Stock' ? productLedger : serviceLedger,
          ledger: type == 'Stock' ? productLedger : serviceLedger,
          quantity: Number(item.quantity_no.toFixed(2)),
          total: productTotal.toFixed(2),
          vatamt: item.vat,
          vatamount: productVat.toFixed(2),
          incomeTaxAmount: productVat.toFixed(2),
          price: item.includevat == '1.00' ? (item.rate - Number(productVat)).toFixed(2) : item.rate,
          itemorder: index + 1,
        };
      });
      let totalPayable: any = (total + tax).toFixed(2);
      let outstanding = val.outStanding;
      let paidStatus: any = "0";
      if (outstanding <= 0) {
        paidStatus = "2";
      } else if (outstanding < totalPayable) {
        paidStatus = "1";
      } else if (outstanding >= totalPayable) {
        paidStatus = "0";
      }

      let obj = {
        cname: user?.staff?.name,
        customerid: user?.staff?.id,
        columns: column,
        invoiceno: invoiceNumber,
        sdate: new Date,
        ldate: new Date,
        inaddress: "__",
        deladdress: "__",
        terms: user?.companyInfo?.defaultTerms,
        quotes: user?.companyInfo?.cusNotes,
        status: 0,
        paid_status: paidStatus,
        issued: "yes",
        type: "sales",
        pagetype: "1",
        total: Number((total + tax).toFixed(2)),
        userid: user?.id,
        adminid: user?.id,
        userdate: new Date,
        paymentInfo: paymentInfo,
        reference: '',
        salesType: "",
        ledger: ledgers,
        email: user.email,
        reccObj: {},
        total_vat: Number(tax.toFixed(2)),
        overall_discount: 0,
        taxable_value: Number(total.toFixed(2)),
        saletype: 'Retail Xpress',
        counterid: Number(counter?.counter_id),
        paymethod: val.paymentMethod == 'cash' ? 'cash' : 'bank',
        companyid: user?.staff?.companyid,
        createdBy: user?.staff?.id,
        isStripe: val?.isStripe,
        shiftid: counter.id,
        credit: val.credit,
        creditCustomerid: val.customer,
        outstanding: val.outStanding,
        redeem: val.redeem
      }
      let URL = 'SaleInvoice/add/staff_create_sale'
      const { data: createRetail, status, message }: any = await POST(URL, obj);
      let templateObj = {
        ...obj,
        payReturn: val.payReturn,
        toPaidAmount: val.toPaidAmount,
        CASHIER: user?.staff?.name,
        DATE: dayjs().format("YYYY-MM-DD"),
        TIME: dayjs().format("HH:mm:ss"),
        RECEIPT: invoiceNumber,
        NO: user?.staff?.id,
        companyInfo: user?.companyInfo,
        symbol: user?.companyInfo?.countryInfo?.symbol
      }

      let templates: any = await printTemplate(templateObj);
      setTemplate(templates);
      if (status) {
        notification.success({
          message: "Success",
          description: "Sales invoice created successfully",
        });
        setModalOpen(true);
        dispatch(clearProduct([]));
        products = Store.getState().retailExpress.products;
        getDatas()
        getInvoiceNo()
        setPaymentModal(false)
        calculation()
        setBtLoading(false)
        return true
      } else {
        notification.error({ message: 'Error', description: message });
        setBtLoading(false)
        return false
      }
    } catch (error) {
      console.log('---------------- error ----------------- ', error)
      notification.error({ message: 'Error in Sale Creation' });
      setBtLoading(false)
      return false
    }
  }

  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + user?.id + '/' + user?.staff?.companyid;
      const { data }: any = await GET(url, null);
      setSlectedBank(data.bankList);
    } catch (error) { }
  };

  const addProductInCart = async () => {
    // setIsChange(true)
    if (products.length) {
      let holdProduct = {
        date: new Date(),
        total: (total + tax).toFixed(2),
        quatity: totalQuatity,
        item: products,
      }
      await dispatch(addHoldProduct(holdProduct));
      await dispatch(clearProduct([]));
      products = Store.getState().retailExpress.products;
      productsStock = Store.getState().retailExpress.holdProducts;
    }
    setTimeout(() => {
      // setIsChange(false);
      calculation();
    }, 1);
  }

  const addCartProductInCart = async (product: any, index: number) => {
    // setIsChange(true)
    await dispatch(addCartProducts(product.item));
    await dispatch(removeHoldProduct(index));
    products = Store.getState().retailExpress.products;
    productsStock = Store.getState().retailExpress.holdProducts;
    setTimeout(() => {
      // setIsChange(false);
      setCartModal(false)
      calculation();
    }, 1);
  }

  const clearHoldInvoice = (index?: number) => {
    // setIsChange(true)
    if (index == 0 || index) {
      dispatch(removeHoldProduct(index))
    } else {
      dispatch(clearHoldProducts())
      setCartModal(false)
    }
    setTimeout(() => {
      // setIsChange(false);
    }, 1);
  }

  const getScanData = async (val: any) => {
    try {
      let body =
      {
        id: user?.id,
        companyid: user?.staff?.companyid,
        type: type,
        category: category,
        name: val ? 0 : product,
        barcode: val,
      }
      const url = `retail/productlist?order=DESC&page=${page}&take=8`;
      const responce: any = await POST(url, body);
      responce.data.forEach((item: any) => {
        item["selection"] = false;
      });
      if (responce.data.length) {
        return responce.data
      } else {
        notification.error({ message: 'Error', description: 'No item in this barcode' });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const qrCodeSuccessCallback = async (decodedText: any,) => {
    if (decodedText) {
      let ScanData: any = await getScanData(decodedText)
      if (ScanData?.length) {
        await handleAddToCart(ScanData[0])
      }
    }
  };


  // const fetchStaffCounterList = async () => {
  //   try {
  //     let obj = {
  //       adminId: user?.id,
  //       companyid: user?.staff?.companyid,
  //       query: counterSearch,
  //       page: 1,
  //       take: 10
  //     }
  //     let url = `billing_counter/list`;
  //     const response: any = await POST(url, obj);
  //     if (response?.status) {
  //       setCounterList(response.datas);
  //     }
  //   } catch (error) {
  //     console.log('------- error -', error)
  //   }
  // }

  // const fetchStaffDifineCounterDetails = async () => {
  //   try {
  //     let obj = {
  //       staffid: user?.staff?.id,
  //       adminId: user?.id,
  //       sDate: dayjs().format('YYYY-MM-DD'),
  //       companyid: user?.staff?.companyid,
  //     }
  //     let url = `counter_details/counter/details`;
  //     const response: any = await POST(url, obj);
  //     if (response.status) {
  //       setCounterDetails(response);
  //     }
  //   } catch (error) {
  //     console.log('------- error -', error)
  //   }
  // }

  // useEffect(() => {
  //   const handleScrollEvent = () => {
  //     console.log('----------- bbb')
  //     const scrollPosition = window.scrollY;
  //     if (scrollPosition >= window.innerHeight) {
  //       console.log('----------- ethi')
  //     } else {
  //       console.log('----------- ethi killa')
  //     }
  //   };
  //   // if () {
  //   //   setIsScrolled(true);
  //   //   setLogoSrc(ScrolledLogo);
  //   // }
  //   window.addEventListener("scroll", handleScrollEvent);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrollEvent);
  //   };
  // }, []);


  const checkout = () => {
    const zeroQItems = products.filter((item: any) => item.quantity_no < 1);
    if (zeroQItems.length > 0) {
      const descriptions = zeroQItems.map((item: any) => item.idescription).join(', ');
      notification.error({
        message: 'No add quantity',
        description: <div>
          The number of <b style={{ color: 'red' }}>{descriptions}</b> is less than zero.
        </div>
      });
    } else {
      if (products?.length) {
        if (!counter?.id) {
          setCounterModal(true)
        } else {
          setPaymentModal(true)
        }
      }
    }
  }

  return (
    <div className="RetailExpress">
      <div className="RetailExpress-box1">
        <Container fluid>
          <Row>
            <Col sm={8} xs={12} style={{ margin: 0, padding: 0 }}>
              <Header
                product={(val: any) => setProduct(val)}
                qrCodeSuccessCallback={qrCodeSuccessCallback}
                type={type}
                typeList={[
                  { name: 'Stock', label: (t as any)("sale.text_23") },
                  { name: 'Service', label: (t as any)("sale.text_24") }
                ]}
                setType={(val: any) => {
                  setType(val)
                  setPage(1)
                }}
              />
              <div>
                <Tabs
                  size="small"
                  defaultActiveKey={category.toString()}
                  items={categories}
                  onChange={(val: any) => {
                    setCategory(val)
                    setPage(1)
                  }}
                  tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
                />
              </div>
              <div className="RetailExpress-box2">
                <Container>
                  <Row>
                    {loding ? (
                      <LoadingBox />
                    ) : (
                      data && data.length ? (
                        data.map((item: any, index: number) => (
                          <Col
                            lg={3}
                            xs={12}
                            key={index}
                            style={{ marginBottom: 20 }}
                          >
                            <ProductItem
                              item={item}
                              onSelect={(item: any) => handleAddToCart(item)}
                              onCount={(val: any) => dispatch(addProductQuantity(val))}
                              selectedItem={products}
                              type={type}
                              currencycode={user?.companyInfo?.countryInfo?.symbol}
                            />
                          </Col>
                        ))
                      ) : (
                        <NoData text={'List of Non-Items!'} />
                      )
                    )}
                  </Row>
                </Container>
              </div>
              <Container>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Pagination
                    total={meta?.itemCount}
                    showSizeChanger={false}
                    responsive={true}
                    showTotal={(total) => `Total ${meta?.itemCount || 0} Products`}
                    onChange={(page) => setPage(page)}
                  />
                </div>
                <br />
                <br />
                <br />
              </Container>
            </Col>
            <Col sm={4} xs={12} style={{ margin: 0, padding: 0 }}>
              <div className="RetailExpress-box3">
                <Row className="mt-3">
                  <Col lg={6}><div className="RetailExpress-txt1">{(t as any)("header.text")} : {invoiceNumber}</div></Col>
                  <Col lg={6}>
                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '15px' }}>
                      <Button style={{ marginRight: '10px' }} size="small" onClick={() => setCartModal(true)}>
                        {(t as any)("sale.text_1")}
                      </Button>
                      <Button danger size="small" onClick={handleClearCart}>
                        {(t as any)("sale.text_2")}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="RetailExpress-box4">
                  {products.length ? products?.map((item: any, index: number) => {
                    return (
                      <ListItem
                        index={index}
                        item={item}
                        isChange={(val: any) => clearAtIndex(val)}
                        add={(val: any) => add(val)}
                        minus={(val: any) => mainus(val)}
                        enter={(val: any) => enter(val)}
                        currencycode={user?.companyInfo?.countryInfo}
                      />
                    );
                  }) : <NoData text={(t as any)("sale.text_10")} />}
                </div>
                <div className="RetailExpress-box7">
                  <table className="RetailExpress-table">
                    <tr>
                      <td className="RetailExpress-txt5">{(t as any)("sale.text_3")}</td>
                      <td className="RetailExpress-txt6">
                        {user?.companyInfo?.countryInfo?.symbol}  {total.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="RetailExpress-txt5">{(t as any)("sale.text_4")}</td>
                      <td className="RetailExpress-txt6">
                        {user?.companyInfo?.countryInfo?.symbol}  {tax.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="RetailExpress-txt5">{(t as any)("sale.text_5")}</td>
                      <td className="RetailExpress-txt6">
                        {user?.companyInfo?.countryInfo?.symbol} {(total + tax).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                  <div
                  // className={isFixed ? "mobileFixedButtonsContainer" : ""}
                  // ref={wrapperRef}
                  >
                    <Row style={{ backgroundColor: '#fff', paddingTop: '5px' }}>
                      <Col sm={5} xs={12} >
                        <Button
                          block
                          size="large"
                          style={{ marginBottom: '10px' }}
                          onClick={() => addProductInCart()}
                        >
                          {(t as any)("sale.text_6")}
                        </Button>
                      </Col>
                      <Col sm={7} xs={12} >
                        <Button
                          block
                          type="primary"
                          size="large"
                          style={{ marginBottom: '10px' }}
                          onClick={() => {
                            checkout()
                            // products?.length ?
                            //   !counter?.id ?
                            //     setCounterModal(true) :
                            //     setPaymentModal(true) :
                            //   null
                          }}
                          loading={btLoding}
                        >
                          {(t as any)("sale.text_7")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {cartModal && (
        !products?.length ? (
          <Modal
            open={cartModal}
            onCancel={() => setCartModal(false)}
            width={500}
            maskClosable={false}
            footer={false}
            title={(t as any)("sale.text_17")}
          >
            <Row>
              {productsStock.length ? (
                <>
                  <Col lg={9} />
                  <Col lg={3}>
                    <Button danger style={{ marginBottom: '10px' }} onClick={() => clearHoldInvoice()} >{(t as any)("sale.text_18")}</Button>
                  </Col>
                  {productsStock?.map((item: any, index: number) => {
                    return (
                      <Col lg={12}>
                        <HoldInvoice
                          item={item}
                          index={index}
                          addCartProductInCart={(item: any, index: number) => addCartProductInCart(item, index)}
                          clearHoldInvoice={(index: any) => clearHoldInvoice(index)}
                        />
                      </Col>
                    )
                  })}
                </>
              ) : (
                <div>{(t as any)("sale.text_26")}</div>
              )}
            </Row>
          </Modal>
        ) : (
          <Modal
            open={cartModal}
            onCancel={() => setCartModal(false)}
            width={500}
            maskClosable={false}
            okText={(t as any)("sale.text_21")}
            cancelText={(t as any)("sale.text_22")}
            title={(t as any)("sale.text_20")}
            onOk={() => handleClearCart()}
          >
            <div><IoAlertCircleOutline size={25} color={'#ffe900'} /> {(t as any)("sale.text_19")}</div>
          </Modal>
        )
      )}
      {paymentModal && (
        <Modal
          open={paymentModal}
          onCancel={() => setPaymentModal(false)}
          width={800}
          maskClosable={false}
          footer={false}
          title={(t as any)("sale.text_11")}
        >
          <RetailExpressPaymentmodal
            onCancel={() => setPaymentModal(false)}
            onFinish={(val: any) => handleSubmit(val)}
            outstanding={(total + tax).toFixed(2)}
            bankList={selectBank}
            amount={(total + tax).toFixed(2)}
          />
        </Modal>
      )}
      {modalOpen ? (
        <PrintModal
          open={modalOpen}
          width={500}
          navigation={true}
          modalClose={(val: any) => setModalOpen(val)}
          template={template}
        />
      ) : null}
      {counterModal ? (
        <ClockInModel
          open={counterModal}
          onCancel={() => setCounterModal(false)}
          text={(t as any)("sale.text_25")}
        />
      ) : null}
    </div >
  );
}

export default RetailExpress;